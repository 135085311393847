<template>
  <Page has-actions>
    <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex">
          <b-button
            v-if="$device.mobile"
            style="z-index: 9"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon
              icon="magnify"
              size="is-small"
            />
          </b-button>
          <b-input
            ref="search"
            v-model="search"
            :placeholder="$t('search')"
            icon="magnify"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            rounded
            @blur="hideMobile()"
          />
        </div>
      </div>
    </div>
    <Card
      v-if="$can('read', 'catalog')"
      content-class="px-0 py-1"
    >
      <div
        v-if="loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="loading"
          :is-full-page="false"
        />
      </div>
      <b-tabs
        v-else
        expanded
        class="paddingless"
        @input="tabChanged"
      >
        <b-tab-item :label="$t('macro')">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-6-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredMacros"
                paginated
                pagination-position="top"
                :per-page="perPageMacro"
                :current-page="currentPageMacro"
                :row-class="
                  (row, index) =>
                    `macro_${row.id}${
                      row.id == updated_macro ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="label"
                  :label="$tc('name', 1)"
                >
                  <span
                    class="is-clickable"
                    @click="openEditMacro(props.row)"
                  >
                    {{ props.row.label }}
                  </span>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="unit"
                  :label="$t('unit')"
                  width="100"
                >
                  {{ props.row.unit }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="nrv.value"
                  :label="$t('nrv')"
                  width="100"
                >
                  {{ props.row.nrv.value || "-" }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="count"
                  :label="$tc('sku', 2)"
                  width="100"
                  centered
                >
                  {{ props.row.count }}
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageMacro"
                :per-pages="perPages"
                :total="FilteredMacros.length"
                :current-page="currentPageMacro"
                @update-per-page="val => (perPageMacro = val)"
                @update-current-page="val => (currentPageMacro = val)"
              />
            </div>

            <div
              id="macro-form"
              class="column is-6-tablet pt-3 is-12-mobile"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editMacro.id ? "add" : "edit") }}</b>
                </p>
                <MicroForm
                  v-if="!loading"
                  size="is-default"
                  hide-cancel
                  :disabled="disabledNutrifacts"
                  :langs="langs"
                  :units="units"
                  :macro="listMacro"
                  :edit-obj="editMacro"
                  :type="'macro'"
                  @updated="macroUpdated"
                  @created="macroCreated"
                  @close="editMacro = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$t('micro')">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-6-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredMicros"
                :default-sort="['label', 'asc']"
                paginated
                pagination-position="top"
                :per-page="perPageMicro"
                :current-page="currentPageMicro"
                :row-class="
                  (row, index) =>
                    `micro_${row.id}${
                      row.id == updated_micro ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  sortable
                  field="label"
                  :label="$tc('name', 1)"
                >
                  <span
                    class="is-clickable"
                    @click="openEditMicro(props.row)"
                  >
                    {{ props.row.label }}
                  </span>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  sortable
                  field="unit"
                  :label="$t('unit')"
                  width="100"
                >
                  {{ props.row.unit }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  sortable
                  field="nrv.value"
                  :label="$t('nrv')"
                  width="100"
                >
                  {{ props.row.nrv.value || "-" }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  sortable
                  field="count"
                  :label="$tc('sku', 2)"
                  width="100"
                  centered
                >
                  {{ props.row.count }}
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageMicro"
                :per-pages="perPages"
                :total="FilteredMicros.length"
                :current-page="currentPageMicro"
                @update-per-page="val => (perPageMicro = val)"
                @update-current-page="val => (currentPageMicro = val)"
              />
            </div>

            <div
              id="micro-form"
              class="column is-6-tablet is-12-mobile pt-3"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editMicro.id ? "add" : "edit") }}</b>
                </p>
                <MicroForm
                  v-if="!loading"
                  size="is-default"
                  hide-cancel
                  :disabled="disabledNutrifacts"
                  :langs="langs"
                  :units="units"
                  :micro="listMicro"
                  :edit-obj="editMicro"
                  :type="'micro'"
                  @updated="microUpdated"
                  @created="microCreated"
                  @close="editMicro = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$tc('aminogram', 2)">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-6-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredAminos"
                paginated
                :default-sort="['label', 'asc']"
                pagination-position="top"
                :per-page="perPageAmino"
                :current-page="currentPageAmino"
                :row-class="
                  (row, index) =>
                    `amino_${row.id}${
                      row.id == updated_amino ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  sortable
                  field="label"
                  :label="$t('nutrition_declaration')"
                >
                  <span
                    class="is-clickable"
                    @click="openEditAmino(props.row)"
                  >
                    {{ props.row.label }}
                  </span>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  sortable
                  field="unit"
                  :label="$t('unit')"
                  width="100"
                >
                  {{ props.row.unit }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  sortable
                  field="count"
                  :label="$tc('sku', 2)"
                  width="100"
                  centered
                >
                  {{ props.row.count }}
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageAmino"
                :per-pages="perPages"
                :total="FilteredAminos.length"
                :current-page="currentPageAmino"
                @update-per-page="val => (perPageAmino = val)"
                @update-current-page="val => (currentPageAmino = val)"
              />
            </div>

            <div
              id="amino-form"
              class="column is-6-tablet pt-3 is-12-mobile"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editAmino.id ? "add" : "edit") }}</b>
                </p>
                <MicroForm
                  v-if="!loading"
                  size="is-default"
                  hide-cancel
                  :disabled="disabledNutrifacts"
                  :langs="langs"
                  :units="units"
                  :micro="listMicro"
                  :edit-obj="editAmino"
                  :type="'amino'"
                  @updated="aminoUpdated"
                  @created="aminoCreated"
                  @close="editAmino = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$tc('ingredient', 2)">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-5-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredIngredients"
                paginated
                pagination-position="top"
                :per-page="perPageIngredient"
                :current-page="currentPageIngredient"
                :row-class="
                  (row, index) =>
                    `ingredient_${row.id}${
                      row.id == updated_ingredient ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="label"
                  :label="$t('label')"
                >
                  <span
                    class="is-clickable"
                    @click="openEditIngredient(props.row)"
                    v-html="props.row.label"
                  />
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageIngredient"
                :per-pages="perPages"
                :total="FilteredIngredients.length"
                :current-page="currentPageIngredient"
                @update-per-page="val => (perPageIngredient = val)"
                @update-current-page="val => (currentPageIngredient = val)"
              />
            </div>
            <div
              id="ingredient-form"
              class="column is-7-tablet is-12-mobile pt-3"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editIngredient.id ? "add" : "edit") }}</b>
                </p>
                <IngredientForm
                  v-if="!loading"
                  size="is-default"
                  :langs="langs"
                  :disabled="disabledNutrifacts"
                  type="ingredient"
                  :edit-obj="editIngredient"
                  @updated="ingredientUpdated"
                  @created="ingredientCreated"
                  @close="editIngredient = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$tc('warning', 2)">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-5-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredWarnings"
                paginated
                pagination-position="top"
                :per-page="perPageWarning"
                :current-page="currentPageWarning"
                :row-class="
                  (row, index) =>
                    `allergen_${row.id}${
                      row.id == updated_warning ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="label"
                  :label="$t('label')"
                >
                  <span
                    class="is-clickable"
                    @click="openEditWarning(props.row)"
                    v-html="props.row.label"
                  />
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageWarning"
                :per-pages="perPages"
                :total="FilteredWarnings.length"
                :current-page="currentPageWarning"
                @update-per-page="val => (perPageWarning = val)"
                @update-current-page="val => (currentPageWarning = val)"
              />
            </div>
            <div
              id="warning-form"
              class="column is-7-tablet is-12-mobile pt-3"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editWarning.id ? "add" : "edit") }}</b>
                </p>
                <IngredientForm
                  v-if="!loading"
                  size="is-default"
                  :langs="langs"
                  :disabled="disabledNutrifacts"
                  type="warning"
                  :edit-obj="editWarning"
                  @updated="WarningUpdated"
                  @created="WarningCreated"
                  @close="editWarning = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$tc('allergen', 2)">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-5-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredAllergens"
                paginated
                pagination-position="top"
                :per-page="perPageAllergen"
                :current-page="currentPageAllergen"
                :row-class="
                  (row, index) =>
                    `warning_${row.id}${
                      row.id == updated_allergen ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="label"
                  :label="$t('label')"
                >
                  <span
                    class="is-clickable"
                    @click="openEditAllergen(props.row)"
                    v-html="props.row.label"
                  />
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageAllergen"
                :per-pages="perPages"
                :total="FilteredAllergens.length"
                :current-page="currentPageAllergen"
                @update-per-page="val => (perPageAllergen = val)"
                @update-current-page="val => (currentPageAllergen = val)"
              />
            </div>
            <div
              id="allergen-form"
              class="column is-7-tablet is-12-mobile pt-3"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editAllergen.id ? "add" : "edit") }}</b>
                </p>
                <IngredientForm
                  v-if="!loading"
                  size="is-default"
                  :langs="langs"
                  :disabled="disabledNutrifacts"
                  type="allergen"
                  :edit-obj="editAllergen"
                  @updated="allergenUpdated"
                  @created="allergenCreated"
                  @close="editAllergen = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$tc('name', 2)">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-5-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredNames"
                paginated
                pagination-position="top"
                :per-page="perPageName"
                :current-page="currentPageName"
                :row-class="
                  (row, index) =>
                    `name_${row.id}${
                      row.id == updated_name ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="label"
                  :label="$t('label')"
                >
                  <span
                    class="is-clickable"
                    @click="openEditName(props.row)"
                  >
                    {{ props.row.label }}
                  </span>
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageName"
                :per-pages="perPages"
                :total="FilteredNames.length"
                :current-page="currentPageName"
                @update-per-page="val => (perPageName = val)"
                @update-current-page="val => (currentPageName = val)"
              />
            </div>
            <div
              id="name-form"
              class="column is-7-tablet is-12-mobile pt-3"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editName.id ? "add" : "edit") }}</b>
                </p>
                <IngredientForm
                  v-if="!loading"
                  size="is-default"
                  :langs="langs"
                  :disabled="disabledNutrifacts"
                  type="name"
                  :edit-obj="editName"
                  @updated="nameUpdated"
                  @created="nameCreated"
                  @close="editName = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$t('directions')">
          <div class="columns is-mobile is-multiline mx-0 is-variable is-5">
            <div
              class="column is-5-tablet is-12-mobile p-0 py-2 column-separator-right"
            >
              <b-table
                :data="FilteredDirections"
                paginated
                pagination-position="top"
                :per-page="perPageDirection"
                :current-page="currentPageDirection"
                :row-class="
                  (row, index) =>
                    `name_${row.id}${
                      row.id == updated_direction ? ' bg-updated' : ''
                    }`
                "
              >
                <b-table-column
                  v-slot="props"
                  header-class="sticky-header"
                  field="label"
                  :label="$t('label')"
                >
                  <span
                    class="is-clickable"
                    @click="openEditDirection(props.row)"
                    v-html="props.row.label"
                  />
                  <!-- <span
                    class="is-clickable"
                    @click="openEditDirection(props.row)"
                  >
                    {{ props.row.label }}
                  </span> -->
                </b-table-column>
                <EmptyBlock
                  slot="empty"
                  icon="barcode"
                />
              </b-table>
              <Pagination
                :per-page="perPageDirection"
                :per-pages="perPages"
                :total="FilteredDirections.length"
                :current-page="currentPageDirection"
                @update-per-page="val => (perPageDirection = val)"
                @update-current-page="val => (currentPageDirection = val)"
              />
            </div>
            <div
              id="direction-form"
              class="column is-7-tablet is-12-mobile pt-3"
            >
              <div class="sticky">
                <p class="is-size-5 pb-5">
                  <b>{{ $t(!editDirection.id ? "add" : "edit") }}</b>
                </p>
                <IngredientForm
                  v-if="!loading"
                  size="is-default"
                  :langs="langs"
                  :disabled="disabledNutrifacts"
                  type="direction"
                  :edit-obj="editDirection"
                  @updated="directionUpdated"
                  @created="directionCreated"
                  @close="editDirection = {}"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </Card>
  </Page>
</template>

<script>
import MicroForm from "@/components/forms/product/NutritionalValue/Micro";
import IngredientForm from "@/components/forms/product/NutritionalValue/Ingredient";

export default {
  components: {
    MicroForm,
    IngredientForm
  },
  data() {
    return {
      disabledNutrifacts: !(
        this.$can("update", "catalog.nutrifacts") ||
        this.$can("create", "catalog.nutrifacts")
      ),
      searchFocused: false,
      search: "",

      loading: true,
      macros: [],
      micros: [],
      aminos: [],
      ingredients: [],
      warnings: [],
      allergens: [],
      names: [],
      directions: [],
      //   filtred
      FilteredMacros: [],
      FilteredMicros: [],
      FilteredAminos: [],
      FilteredIngredients: [],
      FilteredWarnings: [],
      FilteredAllergens: [],
      FilteredNames: [],
      FilteredDirections: [],

      //   Create
      listMacro: [],
      listMicro: [],
      units: [],
      langs: [],

      editMacro: {},
      editMicro: {},
      editAmino: {},
      editIngredient: {},
      editWarning: {},
      editAllergen: {},
      editName: {},
      editDirection: {},

      // updated Item
      updated_macro: null,
      updated_micro: null,
      updated_amino: null,
      updated_ingredient: null,
      updated_allergen: null,
      updated_warning: null,
      updated_name: null,
      updated_direction: null,

      // paggination
      perPages: [20, 50, 100],
      currentPageMacro: 1,
      perPageMacro: 20,
      currentPageMicro: 1,
      perPageMicro: 20,
      perPageAmino: 20,
      currentPageAmino: 1,
      perPageIngredient: 20,
      currentPageIngredient: 1,
      perPageWarning: 20,
      currentPageWarning: 1,
      perPageAllergen: 20,
      currentPageAllergen: 1,
      perPageName: 20,
      currentPageName: 1,
      perPageDirection: 20,
      currentPageDirection: 1
    };
  },
  watch: {
    search(newVal) {
      this.filterData(newVal);
    }
  },
  created() {
    this.updateTitle(this.$t("nutritional_values"));
    this.getData();
  },
  methods: {
    scrollToMobile(el) {
      if (this.$device.mobile) {
        this.$scrollTo(el);
      }
    },
    openEditMacro(item) {
      this.editMacro = item;
      this.scrollToMobile("#macro-form");
    },
    openEditMicro(item) {
      this.editMicro = item;
      this.scrollToMobile("#micro-form");
    },
    openEditAmino(item) {
      this.editAmino = item;
      this.scrollToMobile("#amino-form");
    },
    openEditIngredient(item) {
      this.editIngredient = item;
      this.scrollToMobile("#ingredient-form");
    },
    openEditWarning(item) {
      this.editWarning = item;
      this.scrollToMobile("#warning-form");
    },
    openEditAllergen(item) {
      this.editAllergen = item;
      this.scrollToMobile("#allergen-form");
    },
    openEditName(item) {
      this.editName = item;
      this.scrollToMobile("#name-form");
    },
    openEditDirection(item) {
      this.editDirection = item;
      this.scrollToMobile("#direction-form");
    },
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    tabChanged() {
      this.search = "";
      this.editMacro = {};
      this.editMicro = {};
      this.editAmino = {};
      this.editIngredient = {};
      this.editWarning = {};
      this.editAllergen = {};
    },
    getFilterData(data, search) {
      return data.filter(option => {
        let label = option.label.replace(/<[^>]*>/gi, "");
        return (
          JSON.stringify(label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    filterData(search) {
      this.FilteredMacros = this.getFilterData(this.macros, search);
      this.FilteredMicros = this.getFilterData(this.micros, search);
      this.FilteredAminos = this.getFilterData(this.aminos, search);
      this.FilteredIngredients = this.getFilterData(this.ingredients, search);
      this.FilteredAllergens = this.getFilterData(this.allergens, search);
      this.FilteredWarnings = this.getFilterData(this.warnings, search);
      this.FilteredNames = this.getFilterData(this.names, search);
      this.FilteredDirections = this.getFilterData(this.directions, search);
    },
    getData() {
      if (this.$can("read", "catalog.nutrifacts")) {
        this.loading = true;

        this.$axios
          .get("catalog/nutrifacts")
          .then(({ data }) => {
            this.micros = (data.list.micro || []).reduce((arr, obj) => {
              obj.isParent = true;
              for (let i = 0; i < obj.children.length; i++) {
                obj.children[i].parent_id = obj.id;
              }
              return [...arr, obj, ...obj.children];
            }, []);
            this.aminos = data.list.amino || [];
            this.macros = data.list.macro || [];
            this.macros = (data.list.macro || []).reduce((arr, obj) => {
              obj.isParent = true;
              for (let i = 0; i < obj.children.length; i++) {
                obj.children[i].parent_id = obj.id;
              }
              return [...arr, obj, ...obj.children];
            }, []);
            this.ingredients = data.list.ingredient || [];
            this.directions = data.list.directions || [];
            this.warnings = data.list.warnings || [];
            this.allergens = data.list.allergen || [];
            this.listMicro = data.list.micro || [];
            this.listMacro = data.list.macro || [];
            this.names = data.list.name || [];
            this.langs = data.filters.langs;
            this.units = data.filters.units;
            this.filterData("");
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.loading = false));
      }
    },
    macroCreated(item) {
      this.macros.unshift(item);
      this.FilteredMacros = this.getFilterData(this.macros, this.search);
      this.updated_macro = item.id;
      this.scrollToMobile(`.macro_${item.id}`);
      setTimeout(() => {
        this.updated_macro = null;
      }, 2000);
      this.editMacro = {};
    },
    macroUpdated(item) {
      let index = this.macros.findIndex(obj => obj.id == item.id);
      this.macros.splice(index, 1, item);
      this.FilteredMacros = this.getFilterData(this.macros, this.search);
      let listMacroIndex = this.listMacro.findIndex(l => l.id == item.id);
      this.listMacro.splice(listMacroIndex, 1, item);
      this.updated_macro = item.id;
      this.scrollToMobile(`.macro_${item.id}`);
      setTimeout(() => {
        this.updated_macro = null;
      }, 2000);
      this.editMacro = {};
    },
    microUpdated(item) {
      let index = this.micros.findIndex(obj => obj.id == item.id);
      this.micros.splice(index, 1, item);
      this.FilteredMicros = this.getFilterData(this.micros, this.search);
      let listMicroIndex = this.listMicro.findIndex(l => l.id == item.id);
      this.listMicro.splice(listMicroIndex, 1, item);
      this.updated_micro = item.id;
      this.scrollToMobile(`.micro_${item.id}`);
      setTimeout(() => {
        this.updated_micro = null;
      }, 2000);
      this.editMicro = {};
    },
    microCreated(item) {
      if (item.parent_id) {
        let parentIndex = this.micros.findIndex(m => m.id == item.parent_id);
        this.micros.splice(parentIndex + 1, 0, item);
      } else {
        item.isParent = true;
        this.micros.unshift(item);
      }
      this.FilteredMicros = this.getFilterData(this.micros, this.search);
      this.listMicro.unshift(item);
      this.updated_micro = item.id;
      this.scrollToMobile(`.micro_${item.id}`);
      setTimeout(() => {
        this.updated_micro = null;
      }, 2000);
      this.editMicro = {};
    },
    aminoUpdated(item) {
      let index = this.aminos.findIndex(obj => obj.id == item.id);
      this.aminos.splice(index, 1, item);
      this.FilteredAminos = this.getFilterData(this.aminos, this.search);
      this.updated_amino = item.id;
      this.scrollToMobile(`.amino_${item.id}`);
      setTimeout(() => {
        this.updated_amino = null;
      }, 2000);
      this.editAmino = {};
    },
    aminoCreated(item) {
      this.aminos.unshift(item);
      this.FilteredAminos = this.getFilterData(this.aminos, this.search);
      this.updated_amino = item.id;
      this.scrollToMobile(`.amino_${item.id}`);
      setTimeout(() => {
        this.updated_amino = null;
      }, 2000);
      this.editAmino = {};
    },
    ingredientUpdated(item) {
      let index = this.ingredients.findIndex(obj => obj.id == item.id);
      this.ingredients.splice(index, 1, item);
      this.FilteredIngredients = this.getFilterData(
        this.ingredients,
        this.search
      );
      this.updated_ingredient = item.id;
      this.scrollToMobile(`.ingredient_${item.id}`);
      setTimeout(() => {
        this.updated_ingredient = null;
      }, 2000);
      this.editIngredient = {};
    },
    ingredientCreated(item) {
      this.ingredients.unshift(item);
      this.FilteredIngredients = this.getFilterData(
        this.ingredients,
        this.search
      );
      this.updated_ingredient = item.id;
      this.scrollToMobile(`.ingredient_${item.id}`);
      setTimeout(() => {
        this.updated_ingredient = null;
      }, 2000);
      this.editIngredient = {};
    },
    allergenUpdated(item) {
      let index = this.allergens.findIndex(obj => obj.id == item.id);
      this.allergens.splice(index, 1, item);
      this.FilteredAllergens = this.getFilterData(this.allergens, this.search);
      this.updated_allergen = item.id;
      this.scrollToMobile(`.allergen_${item.id}`);
      setTimeout(() => {
        this.updated_allergen = null;
      }, 2000);
      this.editAllergen = {};
    },
    allergenCreated(item) {
      this.allergens.unshift(item);
      this.FilteredAllergens = this.getFilterData(this.allergens, this.search);
      this.updated_allergen = item.id;
      this.scrollToMobile(`.allergen_${item.id}`);
      setTimeout(() => {
        this.updated_allergen = null;
      }, 2000);
      this.editAllergen = {};
    },
    nameUpdated(item) {
      let index = this.names.findIndex(obj => obj.id == item.id);
      this.names.splice(index, 1, item);
      this.FilteredNames = this.getFilterData(this.names, this.search);
      this.updated_name = item.id;
      this.scrollToMobile(`.name_${item.id}`);
      setTimeout(() => {
        this.updated_name = null;
      }, 2000);
      this.editName = {};
    },
    nameCreated(item) {
      this.names.unshift(item);
      this.FilteredNames = this.getFilterData(this.names, this.search);
      this.updated_name = item.id;
      this.scrollToMobile(`.name_${item.id}`);
      setTimeout(() => {
        this.updated_name = null;
      }, 2000);
      this.editName = {};
    },
    WarningUpdated(item) {
      let index = this.warnings.findIndex(obj => obj.id == item.id);
      this.warnings.splice(index, 1, item);
      this.FilteredWarnings = this.getFilterData(this.warnings, this.search);
      this.updated_warning = item.id;
      this.scrollToMobile(`.warning_${item.id}`);
      setTimeout(() => {
        this.updated_warning = null;
      }, 2000);
      this.editWarning = {};
    },
    WarningCreated(item) {
      this.warnings.unshift(item);
      this.FilteredWarnings = this.getFilterData(this.warnings, this.search);
      this.updated_warning = item.id;
      this.scrollToMobile(`.warning_${item.id}`);
      setTimeout(() => {
        this.updated_warning = null;
      }, 2000);
      this.editWarning = {};
    },
    directionUpdated(item) {
      let index = this.directions.findIndex(obj => obj.id == item.id);
      this.directions.splice(index, 1, item);
      this.FilteredDirections = this.getFilterData(
        this.directions,
        this.search
      );
      this.updated_direction = item.id;
      this.scrollToMobile(`.direction_${item.id}`);
      setTimeout(() => {
        this.updated_direction = null;
      }, 2000);
      this.editDirection = {};
    },
    directionCreated(item) {
      this.directions.unshift(item);
      this.FilteredDirections = this.getFilterData(
        this.directions,
        this.search
      );
      this.updated_direction = item.id;
      this.scrollToMobile(`.direction_${item.id}`);
      setTimeout(() => {
        this.updated_direction = null;
      }, 2000);
      this.editDirection = {};
    }
  }
};
</script>
